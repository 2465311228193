<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    prefix?: string
    suffix?: string
    showSign?: boolean
    value?: number | null | undefined
    tooltip?: string
    invertSignColor?: boolean
    currency?: string
    locale?: string
    showSymbol?: boolean
  }>(),
  {
    prefix: undefined,
    suffix: undefined,
    showSign: false,
    value: undefined,
    tooltip: undefined,
    invertSignColor: false,
    locale: 'en-AU',
    currency: 'AUD',
    showSymbol: false,
  },
)

const showSign = computed(
  () =>
    props.showSign && props.value && props.value !== 0 && !isNaN(props.value),
)
const signColor = computed(() => {
  if (props.invertSignColor) {
    return props.value! > 0 ? 'text-danger' : 'text-success'
  } else {
    return props.value! > 0 ? 'text-success' : 'text-danger'
  }
})

const displayValue = computed(() => {
  let formattedValue = Intl.NumberFormat(props.locale, {
    style: 'currency',
    currency: props.currency,
    currencyDisplay: props.showSymbol ? 'symbol' : 'code',
  })
    .format(props.value || 0)
    .replace(props.currency, '')

  if (props.showSign) {
    formattedValue = formattedValue.replace('-', '')
  }

  return formattedValue
})
</script>

<template>
  <Tooltip
    placement="top"
    class="min-w-max bg-gray-900 text-xs"
    :content="tooltip"
  >
    <span
      v-if="value !== undefined && value !== null && !isNaN(value)"
      class="flex items-center gap-0.5"
    >
      <!-- Positive Negative Symbol -->
      <span
        v-if="showSign"
        class="-mt-0.5 text-lg font-bold"
        :class="signColor"
      >
        {{ props.value! > 0 ? '+' : '-' }}
      </span>

      <!-- Prefix -->
      <span v-if="prefix" class="-mr-1 text-xs text-gray-500">
        {{ prefix }}
      </span>

      <!-- Value -->
      {{ displayValue }}

      <!-- Suffix -->
      <span v-if="suffix" class="text-xs text-gray-500">
        {{ suffix }}
      </span>
    </span>
    <span v-else class="flex w-full text-gray-500"> N/A </span>
  </Tooltip>
</template>
